import { useRef } from "react";
import { usePreferencesContext } from "../../../contexts/PreferencesContext";
import { useFolderRoots } from "../../../lib/hooks/useFolderRoots";
import { useFunctionOnBottomOfElement } from "../../../lib/hooks/useFunctionOnBottomOfElement";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";
import { Spinner } from "../../atoms";
import {
  FileTree,
  TreeDocumentComponent,
} from "../../atoms/FileTree/FileTreeIndexPage";
import { useDocumentListContext } from "../../layout/DocumentLayout/DocumentLayout";

export default function IndexPageBrowseTable() {
  const { documentListData, fetchMoreDocumentList, loading } =
    useDocumentListContext();

  const { communitySlug } = useLobbyRouter();
  const { currentMode } = usePreferencesContext();

  const { folderRoots } = useFolderRoots({ documentListData });

  const indexTabelRef = useRef<HTMLDivElement>(null);

  const { debouncedFunctionAtEndOfElement } = useFunctionOnBottomOfElement({
    ref: indexTabelRef,
    func: fetchMoreDocumentList,
  });

  if (loading && !documentListData) return <Spinner />;

  return (
    <div ref={indexTabelRef} onWheel={debouncedFunctionAtEndOfElement}>
      <div className="flex flex-row flex-wrap">
        {folderRoots.map((root) => {
          return <FileTree key={root.name} rootFolder={root} />;
        })}
      </div>
      <div className="grid pt-6 pr-8">
        <div>
          <div className="pt-8 text-xl font-semibold text-gray-600 border-b-2"></div>
          {documentListData?.documents?.documents.map((document) => {
            return (
              <TreeDocumentComponent
                key={document.documentId}
                document={document}
                href={`/${communitySlug}/${document.slug}/${currentMode}`}
              />
            );
          })}
          {loading && (
            <div className="m-10 ">
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
