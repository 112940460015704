import classNames from "classnames";
import React, { useState } from "react";
import { Button, SearchBar } from "../../atoms";
import { LandingIcon } from "../../../generated/graphql";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";
import { defaultLandingBackgroundImage } from "../../../lib/constants";

interface HomeScreenProps {
  backgroundImage?: string;
  icons?: LandingIcon[];
}

export function HomeScreen({ backgroundImage, icons = [] }: HomeScreenProps) {
  const [value, setValue] = useState("");
  const { communitySlug, push } = useLobbyRouter();
  const newPath = `/${communitySlug}/search`;

  const setSearchValue = (val: string) => {
    setValue(val);
  };

  const submit = (e: React.FormEvent) => {
    e?.preventDefault();
    push({
      pathname: newPath,
      query: { query: value, origin: "Community home" },
    });
    return false;
  };

  const fetchedUrl = backgroundImage || defaultLandingBackgroundImage;

  return (
    <div
      style={{
        background: `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${fetchedUrl}) no-repeat 100% 100%`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
      className={classNames(
        "w-screen",
        icons.length > 0 ? " min-h-[90vh]" : "min-h-auto"
      )}
    >
      <div className="flex flex-col items-center pt-40">
        <form onSubmit={submit} className="flex w-full max-w-lg px-3">
          <SearchBar
            className="w-full px-4 py-2 mr-4 text-white border-2 rounded-xl border-white/60 placeholder:text-white/50 focus:outline-none focus:border-white backdrop-blur bg-white/10"
            setValue={setSearchValue}
          />
          <Button
            text="Search"
            backgroundColor="bg-white/40"
            className="font-bold"
          />
        </form>
        <div className="mt-12 mb-12">
          <div className="grid grid-cols-4 gap-y-4 gap-x-4 sm:gap-y-8 sm:gap-x-16">
            {icons.map((link) => (
              <div key={link.id}>
                <a
                  href={link.linkUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="group"
                >
                  <div className="flex flex-col items-center">
                    <img
                      src={link.imageUrl}
                      alt="" // no alt because it represents the text
                      className="group-active:brightness-90 h-16 w-16 sm:w-24 sm:h-24 rounded-[0.75rem] sm:rounded-[1rem]"
                    />
                    <div className="mt-2 text-sm font-medium text-white sm:text-lg">
                      {link.name}
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
