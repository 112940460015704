import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { LoadingLayout } from "../../components/layout";
import { CommunityHomeLayout } from "../../components/layout/CommunityHomeLayout/CommunityHomeLayout";
import IndexPageBrowseTable from "../../components/molecules/IndexPageBrowseTable/IndexPageBrowseTable";
import { HomeScreen } from "../../components/organisms/HomeScreen/HomeScreen";
import { usePreferencesContext } from "../../contexts/PreferencesContext";
import {
  Community as CommunityType,
  QueryCommunityArgs,
} from "../../generated/graphql";
import { defaultLandingBackgroundImage } from "../../lib/constants";
import useLobbyRouter from "../../lib/hooks/useLobbyRouter";
import { CommunityQuery } from "../../lib/queries/Communities";

export default function Community() {
  const { communitySlug, events } = useLobbyRouter();
  const { setIsSidebarOpen, isSidebarOpen } = usePreferencesContext();
  const [initialSidebar] = useState(isSidebarOpen);

  const [fetchCommunity, { loading, data: communityData }] = useLazyQuery<
    { community: CommunityType },
    QueryCommunityArgs
  >(CommunityQuery, {
    fetchPolicy: "cache-and-network",
  });

  // set sidebar closed on community index page load
  useEffect(() => {
    setIsSidebarOpen(false);
  }, []);

  // set sidebar open if it was open on community index page load or if it's open now
  useEffect(() => {
    function handler() {
      setIsSidebarOpen(initialSidebar || isSidebarOpen);
    }

    events.on("routeChangeStart", handler);
    return () => events.off("routeChangeStart", handler);
  }, [initialSidebar, isSidebarOpen, setIsSidebarOpen, events]);

  useEffect(() => {
    if (!communitySlug || communitySlug === "[community]") return;

    fetchCommunity({
      variables: { communitySlug },
    });
  }, [communitySlug]);

  if (loading) return <LoadingLayout />;

  return (
    <CommunityHomeLayout>
      <div>
        <HomeScreen
          backgroundImage={
            communityData?.community?.landingPrefs.backgroundImageUrl ||
            defaultLandingBackgroundImage
          }
          icons={communityData?.community?.landingIcons}
        />

        <div className="flex flex-col justify-start flex-grow w-screen px-10 pb-10 mx-auto max-w-7xl">
          <div className="pt-10">
            <div className="text-2xl font-bold text-gray-600">Browse</div>
            <IndexPageBrowseTable />
          </div>
        </div>
      </div>
    </CommunityHomeLayout>
  );
}
