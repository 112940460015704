import { useLazyQuery } from "@apollo/client";
import { Transition } from "@headlessui/react";
import { KBarProvider } from "kbar/lib/KBarContextProvider";
import Head from "next/head";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useAuthenticationContext } from "../../../contexts/AuthenticationContext";
import { usePreferencesContext } from "../../../contexts/PreferencesContext";
import {
  Community,
  Document,
  Folder,
  QueryDocumentsArgs,
} from "../../../generated/graphql";
import client from "../../../lib/apollo/apollo-client";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";
import { CommunitySidebarQuery } from "../../../lib/queries/Communities";
import { DocumentSidebar } from "../../organisms";
import CommandK from "../../organisms/CommandK/CommandK";
import { BaseLayout } from "../BaseLayout/BaseLayout";
import { DocumentListContext } from "../DocumentLayout/DocumentLayout";

interface SidebarQueryInterface {
  folders: { folders: Folder[]; hasMore: boolean };
  documents: { documents: Document[]; hasMore: boolean };
  community?: Community;
  language?: string;
}

export const CommunityHomeLayout = ({ children }: PropsWithChildren<{}>) => {
  const { communitySlug, locale } = useLobbyRouter();
  const { isSidebarOpen } = usePreferencesContext();
  const { token } = useAuthenticationContext();
  const [width, setWidth] = useState(0);
  const [cursor, setCursor] = useState<string | undefined>(undefined);

  // Using useState loading because the refetch loading is not triggering
  const [loading, setLoading] = useState(false);

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  useEffect(() => {
    if (!isSidebarOpen) {
      setWidth(0);
    }
  }, [isSidebarOpen]);

  const take = 100;

  const [sideBarQuery, { data, error, refetch, fetchMore }] = useLazyQuery<
    SidebarQueryInterface,
    QueryDocumentsArgs
  >(CommunitySidebarQuery, {
    variables: {
      communitySlug,
      pagination: { cursor, take },
      language: locale,
    },
  });

  const refetchDocumentList = useCallback(
    async (locale: string) => {
      if (
        communitySlug &&
        communitySlug !== "[community]" &&
        communitySlug !== "my-workspace"
      )
        setLoading(true);
      await refetch({
        communitySlug,
        pagination: { cursor, take },
        language: locale,
      });
      setLoading(false);
    },
    [refetch, communitySlug, locale]
  );

  const fetchMoreDocumentList = async () => {
    if (!data?.documents.hasMore) return;

    setCursor(
      data?.documents?.documents[data.documents?.documents.length - 1]
        .documentId
    );

    setLoading(true);
    await fetchMore({
      variables: {
        pagination: {
          cursor,
          skip: 1,
          take,
        },
      },
    });
    setLoading(false);
  };

  // Run sidebar query whenever communitySlug or token changes
  useEffect(() => {
    const runSideBarQuery = async () => {
      setLoading(true);
      await sideBarQuery();
      setLoading(false);
    };

    if (communitySlug !== "[community]" && communitySlug !== "my-workspace")
      runSideBarQuery();
  }, [communitySlug, token]);

  // Clear cache when no data is obtained from SidebarQuery
  // This fixes the bug from when switching from a community where you
  // do have permissions (cache is loaded) to a community where you don't
  // will load data from cache so the documents/folders will appear from
  // previous community
  useEffect(() => {
    if (!loading && (!data || error)) {
      // Fixes LOB-504 Invariant violation
      client.stop();
      client.clearStore();
    }
  }, [data, loading, error]);

  return (
    <KBarProvider>
      <BaseLayout headerStyle={{ marginLeft: `${width}px` }}>
        <CommandK />

        <div
          className={`flex flex-col items-center justify-start h-screen overflow-hidden -mt-24`}
        >
          {/* TODO: This isn't rendering because it's loaded async. */}
          <Head>
            <title>
              {data?.community?.name
                ? `Lobby • ${data?.community?.name}`
                : "Lobby"}
            </title>
            <meta
              name="viewport"
              content="initial-scale=1.0, width=device-width"
            />
          </Head>

          <DocumentListContext.Provider
            value={{
              documentListData: data,
              error,
              loading,
              refetchDocumentList,
              fetchMoreDocumentList,
            }}
          >
            <div className="flex flex-row flex-grow w-full overflow-scroll">
              <div className="pt-3 bg-gray-50">
                <Transition
                  as={"div"}
                  show={isSidebarOpen}
                  enter="transition ease-in-out duration-200 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-200 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <div ref={measuredRef}>
                    <DocumentSidebar />
                  </div>
                </Transition>
              </div>
              <div className="flex w-full h-full overflow-scroll lg:justify-center">
                {children}
              </div>
            </div>
          </DocumentListContext.Provider>
        </div>
      </BaseLayout>
    </KBarProvider>
  );
};
