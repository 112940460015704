import { ChevronDownIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import Link from "next/link";
import { usePreferencesContext } from "../../../contexts/PreferencesContext";
import { Document } from "../../../generated/graphql";
import { ReducedFolder } from "../../../interfaces";
import { useFolderTranslationName } from "../../../lib/hooks/useFolderTranslationName";
import useLobbyRouter from "../../../lib/hooks/useLobbyRouter";

interface FileTreeInterface {
  rootFolder: ReducedFolder;
}

const TreeFolderComponent = ({
  folder,
  showChildren,
  onClick,
}: {
  folder: ReducedFolder;
  showChildren: boolean;
  onClick: () => void;
}) => {
  const { folderName } = useFolderTranslationName({ folder });

  return (
    <div
      onClick={onClick}
      className="flex flex-row items-center justify-between px-2 py-1 mt-3 text-lg font-semibold text-gray-600 border-b-2 hover:bg-gray-100 hover:cursor-pointer"
    >
      <h1>{folderName}</h1>
      <div className="w-4 h-4">
        {showChildren ? <ChevronDownIcon /> : <ChevronLeftIcon />}
      </div>
    </div>
  );
};

export const TreeDocumentComponent = ({
  document,
  href,
}: {
  document: Document;
  href: string;
}) => {
  const { locale } = useLobbyRouter();

  return (
    <Link href={href}>
      <a>
        <div
          className={classNames(
            document?.translations?.find(
              (object) => object.translation.language === locale
            )
              ? ""
              : "opacity-50",
            !document.folder && "",
            "pt-3 text-sm font-normal text-gray-800 hover:underline"
          )}
        >
          <p>{document.name}</p>
        </div>
      </a>
    </Link>
  );
};

export const FileTree = ({ rootFolder }: FileTreeInterface) => {
  const {
    openFoldersCommunityIndexPage,
    setOpenFoldersCommunityIndexPage,
    currentMode,
  } = usePreferencesContext();
  const { communitySlug } = useLobbyRouter();
  const folderKey = `${communitySlug},${rootFolder.name}`;

  return (
    <div className="px-3 w-96">
      <TreeFolderComponent
        folder={rootFolder}
        showChildren={openFoldersCommunityIndexPage[folderKey]}
        onClick={() => {
          setOpenFoldersCommunityIndexPage(
            folderKey,
            !openFoldersCommunityIndexPage[folderKey]
          );
        }}
      />
      {openFoldersCommunityIndexPage[folderKey] && (
        <>
          <FileTreeRecursive folders={rootFolder.subFolders} />
          <div>
            {rootFolder.documents.map((document) => {
              return (
                <TreeDocumentComponent
                  key={document.slug}
                  document={document}
                  href={`/${communitySlug}/${document.slug}/${currentMode}`}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

const FileTreeRecursive = ({ folders }: { folders: ReducedFolder[] }) => {
  const { communitySlug } = useLobbyRouter();
  const {
    openFoldersCommunityIndexPage,
    setOpenFoldersCommunityIndexPage,
    currentMode,
  } = usePreferencesContext();

  return (
    <div>
      {folders.map((folder) => {
        const folderKey = `${communitySlug},${folder.name}`;
        return (
          <div key={folder.name} className="ml-5 border-gray-200">
            <TreeFolderComponent
              folder={folder}
              showChildren={openFoldersCommunityIndexPage[folderKey]}
              onClick={() => {
                setOpenFoldersCommunityIndexPage(
                  folderKey,
                  !openFoldersCommunityIndexPage[folderKey]
                );
              }}
            />
            {openFoldersCommunityIndexPage[folderKey] && (
              <>
                <FileTreeRecursive folders={folder.subFolders} />
                <div>
                  {folder.documents.map((document) => {
                    return (
                      <TreeDocumentComponent
                        key={document.slug}
                        document={document}
                        href={`/${communitySlug}/${document.slug}/${currentMode}`}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
